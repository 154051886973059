import React, { Component } from 'react';
import { connect } from "react-redux";
import QRCode from "../components/QRCode";
import SimpleHeader from "../components/SimpleHeader";
import BlockCustomizeType from "../components/BlockCustomizeType";
import { push } from 'react-router-redux';
import BlockCustomizeStyle from "../components/BlockCustomizeStyle";
import BlockCustomizeText from "../components/BlockCustomizeText";
import BlockCustomizeImage from "../components/BlockCustomizeImage";
import BlockCustomizeIcon from "../components/BlockCustomizeIcon";
import BlockCustomizeAdvanced from "../components/BlockCustomizeAdvanced";


class PanelCustomize extends Component {

    state = {
        selectedTab: 0,
    };

    tabArr = {
        1: [1, 3, 5],
        2: [1, 2, 3, 5, 6],
        3: [1, 2, 3, 5, 6],
        4: [1, 4, 5, 6],
    };

    goBack = () => {
        this.props.dispatch(push('/' + this.props.selectedMenu));
    };

    download = () => {
        this.props.dispatch(push('/download'));
    };

    handleSelectTypeChange = (num) => {
        this.setState({ selectedTab: num });
    };

    componentDidMount() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    render() {
        return (
            <React.Fragment>
                <SimpleHeader title='Кастомизация'/>
                <div className="container">
                    <div className="full-height">
                    <div className="white-block">
                        <div className="white-block__inner">
                            <div className="tab-type">
                                <div className="tab-type__header">
                                    <div className={ "tab-type__title" + (this.tabArr[this.props.selectedType][this.state.selectedTab] === 1 ? ' tab-type__title--active' : '') } onClick={ () => this.handleSelectTypeChange(this.tabArr[this.props.selectedType].indexOf(1)) }>Тип кода</div>
                                    { (this.props.selectedType === 2 || this.props.selectedType === 3) && <div className={ "tab-type__title" + (this.tabArr[this.props.selectedType][this.state.selectedTab] === 2 ? ' tab-type__title--active' : '') } onClick={ () => this.handleSelectTypeChange(this.tabArr[this.props.selectedType].indexOf(2)) }>Текст</div> }
                                    { (this.props.selectedType !== 4) && <div className={ "tab-type__title" + (this.tabArr[this.props.selectedType][this.state.selectedTab] === 3 ? ' tab-type__title--active' : '') } onClick={ () => this.handleSelectTypeChange(this.tabArr[this.props.selectedType].indexOf(3)) }>Стиль</div> }
                                    { (this.props.selectedType === 4) && <div className={ "tab-type__title" + (this.tabArr[this.props.selectedType][this.state.selectedTab] === 4 ? ' tab-type__title--active' : '') } onClick={ () => this.handleSelectTypeChange(this.tabArr[this.props.selectedType].indexOf(4)) }>Изображение</div> }
                                    <div className={ "tab-type__title" + (this.tabArr[this.props.selectedType][this.state.selectedTab] === 5 ? ' tab-type__title--active' : '') } onClick={ () => this.handleSelectTypeChange(this.tabArr[this.props.selectedType].indexOf(5)) }>Иконка</div>
                                    { (this.props.selectedType !== 1) && <div className={ "tab-type__title" + (this.tabArr[this.props.selectedType][this.state.selectedTab] === 6 ? ' tab-type__title--active' : '') } onClick={ () => this.handleSelectTypeChange(this.tabArr[this.props.selectedType].indexOf(6)) }>Дополнительно</div> }
                                </div>
                                <div className="tab-type__body">
                                    <div className={ "tab-type__item" + (this.tabArr[this.props.selectedType][this.state.selectedTab] === 1 ? ' tab-type__item--active' : '') }>
                                        <BlockCustomizeType/>
                                    </div>
                                    <div className={ "tab-type__item" + (this.tabArr[this.props.selectedType][this.state.selectedTab] === 2 ? ' tab-type__item--active' : '') }>
                                        <BlockCustomizeText/>
                                    </div>
                                    <div className={ "tab-type__item" + (this.tabArr[this.props.selectedType][this.state.selectedTab] === 3 ? ' tab-type__item--active' : '') }>
                                        <BlockCustomizeStyle/>
                                    </div>
                                    <div className={ "tab-type__item" + (this.tabArr[this.props.selectedType][this.state.selectedTab] === 4 ? ' tab-type__item--active' : '') }>
                                        <BlockCustomizeImage/>
                                    </div>
                                    <div className={ "tab-type__item" + (this.tabArr[this.props.selectedType][this.state.selectedTab] === 5 ? ' tab-type__item--active' : '') }>
                                        <BlockCustomizeIcon/>
                                    </div>
                                    <div className={ "tab-type__item" + (this.tabArr[this.props.selectedType][this.state.selectedTab] === 6 ? ' tab-type__item--active' : '') }>
                                        <BlockCustomizeAdvanced/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="white-block">
                        <div className="white-block__inner pad-24">
                            <QRCode/>
                        </div>
                    </div>
                    </div>
                    <div className="footer-btn">
                        <div className="btn btn-gray" onClick={ this.goBack }>Сохранить</div>
                        <div className="btn btn-blue" onClick={ this.download }>Скачать</div>
                    </div>
                </div>

            </React.Fragment>
        );
    }

}

function mapStateToProps(state) {
    return {
        selectedMenu: state.globals.selectedMenu,
        selectedType: state.settings.look.selectedType,
    };
}

export default connect(mapStateToProps)(PanelCustomize);