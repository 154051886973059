import React, { Component } from 'react';
import { connect } from "react-redux";
import '../../css/panel-complete.css';
import BlockCell from "../components/BlockCell";
import { push } from 'react-router-redux';
import {googleEvent, yandexEvent} from "../services/_functions";

class PanelComplete extends Component {

    clickRetargetingHandler = () => {
        googleEvent('Retargeting', 'Read more mob');
        yandexEvent('retargeting_read_more_mob');
        const linkEl = document.getElementById('link-retargeting');
        linkEl.click();
    };

    clickCommentHandler = () => {
        this.props.dispatch(push('feedback'));
    };

    clickCreateAnother = () => {
        this.props.dispatch(push(this.props.selectedMenu));
    };

    clickWherePrintHandler = () => {
        this.props.dispatch(push('print'));
    };

    render() {
        return (
            <div className="panel-complete">
                <div className="qr-complete">
                    <div className="icon-check"/>
                    <div className="complete-title">QR-код загружен</div>
                </div>
                <a id="link-retargeting" className="hide-it" href="https://vk.com/@business-retarg-qr" target="_blank"/>
                <div className="complete-cell-blocks">
                    <BlockCell title='Где я могу распечатать?' image='geo' clickHandler={this.clickWherePrintHandler}/>
                    <BlockCell title='Ретаргетинг по QR-коду' image='megafon' clickHandler={this.clickRetargetingHandler}/>
                    <BlockCell title='Оставить отзыв' image='comment' clickHandler={this.clickCommentHandler}/>
                    <BlockCell title='Создать другой QR' image='qr-code' clickHandler={this.clickCreateAnother}/>
                </div>
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        selectedMenu: state.globals.selectedMenu,
    };
}

export default connect(mapStateToProps)(PanelComplete);
