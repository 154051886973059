import React, { Component } from 'react';
import { connect } from "react-redux";
import Header from "../components/Header";
import BlockLook from "../components/BlockLook";
import Footer from "../components/Footer";
import {
    setAdvancedLink,
    setAdvancedTitle,
    setAdvancedTopIcon, setAdvancedTopIconLoaded, setAdvancedWithImageAndText, setLinkUrl,
    setLookBgImage, setLookBgLoaded
} from "../store/settings/actions";
import {googleEvent, toBase64, yandexEvent} from "../services/_functions";
import {UPDATE_TIME} from "../services/_locals";
import {getVkUserInfo} from "../services/axios";


class PanelLink extends Component {

    state = {
        typing: false,
        timeout: undefined,
    };

    updateGoogleTimeout;

    changeUrlHandler = async(e) => {
        const val = e.target.value;

        clearTimeout(this.state.timeout);
        this.setState({
            timeout:
                setTimeout(() => {
                    this.props.dispatch(setLinkUrl(val));
                    this.getUser(val);
                }, UPDATE_TIME)
        });

        clearTimeout(this.updateGoogleTimeout);
        this.updateGoogleTimeout = setTimeout(() => {
            googleEvent('Edit', 'Link', 'New_link');
            yandexEvent('edit_new_link');
        }, 2500);
    };

    getUser = async(val) => {
        if (this.parseUrl(val) !== false) {
            const vkId = this.parseUrl(val).replace('/', '');

            const isLarge = this.props.lookType === 4;
            const getInfoResult = await getVkUserInfo(vkId, isLarge);

            if (getInfoResult.result === 'success') {
                this.props.dispatch(setAdvancedWithImageAndText(true));
                const wholeName = (getInfoResult.data.first_name + ' ' + getInfoResult.data.last_name).length > 18 ? getInfoResult.data.last_name : getInfoResult.data.first_name + ' ' + getInfoResult.data.last_name;
                this.props.dispatch(setAdvancedTitle(wholeName));
                this.props.dispatch(setAdvancedLink('@' + getInfoResult.data.domain));

                // Для 2 и 3 типа
                toBase64(getInfoResult.filename_small, result => {
                    this.props.dispatch(setAdvancedTopIcon(result));
                    this.props.dispatch(setAdvancedTopIconLoaded(true));
                });

                // Для 4 типа
                toBase64(getInfoResult.filename_large, result => {
                    this.props.dispatch(setLookBgImage(result));
                    this.props.dispatch(setLookBgLoaded(true));
                });
            }
        }
    };

    parseUrl = (url) => {
        if (url.indexOf('https://vk.com/') !== -1 || url.indexOf('https://m.vk.com/') !== -1 || url.indexOf('m.vk.com/') !== -1 || url.indexOf('vk.com/') !== -1) {
            const startPos = url.indexOf('vk.com/') + 7;
            return url.substr(startPos, url.length);
        }
        return false;
    };

    componentDidMount = () => {
        document.getElementById('full-height').style.minHeight = 'calc(' + window.innerHeight + 'px - 126px)';
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    };

    render() {
        return (
            <React.Fragment>
                <Header title='Ссылка' link="link"/>
                <div className="container" id="full-height">
                    <div className="white-block">
                        <div className="white-block__inner">
                            <div className="form-input">
                                <div className="form-input__name">URL-адрес</div>
                                <input type="text" placeholder="Введите адрес"  onChange={ this.changeUrlHandler } defaultValue={ this.props.linkUrl }/>
                            </div>
                            {/*<div className="form-checkbox form-checkbox__info">*/}
                                {/*<input type="checkbox" id="check_link" value=""/>*/}
                                    {/*<label htmlFor="check_link">Динамическая ссылка</label>*/}
                                    {/*<span>Вы сможете менять ссылку динамического	QR-кода после его создания и получать полную статистику его сканирования.</span>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <BlockLook/>
                </div>
                <Footer/>
            </React.Fragment>
        );
    }

}

function mapStateToProps(state) {
    return {
        linkUrl: state.settings.link.url,
        lookType: state.settings.look.selectedType,
        isRetargetingClose: state.globals.isRetargetingClose,
    };
}

export default connect(mapStateToProps)(PanelLink);
