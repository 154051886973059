import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../css/input-select.css';
import {setLookCounterVisibility} from "../store/settings/actions";


class InputSelect extends Component {

    state = {
        focused: false,
        text: (this.props.value !== undefined ? this.props.value : ''),
    };

    onBlur = () => {
        this.setState({ focused: false });
    };

    onFocus = () => {
        this.setState({ focused: true })
    };

    fillInput = (text, isHide) => {
        this.changeInput(text);
        if (isHide) this.props.dispatch(setLookCounterVisibility(false));
        else this.props.dispatch(setLookCounterVisibility(true));
    };

    changeInput = (value) => {
        if (this.props.maxlen >= value.length) {
            this.setState({
                text: value
            });
            if (this.props.changeFunc !== undefined) this.props.changeFunc(this.props.maxlen - value.length, value);
        }
    };

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.refs['inpt'].blur()
        }
        this.props.dispatch(setLookCounterVisibility(true));
    };

    renderList = () => {
        let list = [];
        let key = Math.random() * 10000000000;
        for (let i = 0; i < this.props.options.length; i++) {
            list.push(<div key={ "inp-sel-" + key + '-' +i } className="Input-select__list_option" onMouseDown={ () => this.fillInput(this.props.options[i], true) }>{ this.props.options[i] }</div>)
        }
        return list;
    };

    render() {
        return (
            <div className="Input-select">
                <div className="Input-select__input-block">
                    <input
                        placeholder={ this.props.placeholder }
                        type="text"
                        value={ this.props.lookText }
                        onFocus={ this.onFocus }
                        onBlur={ this.onBlur }
                        onChange={ e => this.changeInput(e.target.value) }
                        onKeyDown={ this.handleKeyDown }
                        ref="inpt"
                    />
                    <div className="Input-select__input-block_icon"/>
                </div>
                <div className={ "Input-select__list" + (this.state.focused ? ' Input-select__list--open' : '') }>
                    { this.props.withClear && <div key={ "inp-sel-clear" } className="Input-select__list_option" onMouseDown={ () => this.fillInput('', false) }>Без текста</div> }
                    { this.renderList() }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lookText: state.settings.look.text,
    };
};

export default connect(mapStateToProps)(InputSelect);