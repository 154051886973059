import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setModal } from "../store/globals/actions";
import { push } from 'react-router-redux';


class ModalReviewDone extends Component {

    closeModal = () => {
        this.props.dispatch(setModal(''));
        this.props.dispatch(push('/'));
    };

    closeModalOwner = (e) => {
        if (e.target.classList.contains('ModalOwner')) {
            this.props.dispatch(setModal(''));
            this.props.dispatch(push('/'));
        }
    };

    render() {
        return (
            <div className="ModalOwner" onClick={ (e) => this.closeModalOwner(e) }>
                <div className="Modal">
                    <div className="Modal__content">
                        <div className="Modal__header">
                            <div className="Modal__header-title">Отзыв успешно оставлен</div>
                            <div className="Modal__close" onClick={ this.closeModal }/>
                        </div>
                        <div className="Modal__body">
                            <p>Спасибо за Вашу обратную связь</p>
                            <div className="Modal__buttons">
                                <div className="btn btn-blue" onClick={ this.closeModal }>ОК</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    };
};

export default connect(mapStateToProps)(ModalReviewDone);