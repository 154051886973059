import React, { Component } from 'react';
import { connect } from "react-redux";
import SimpleHeader from "../components/SimpleHeader";
import '../../css/panel-print.css';
import Typography from "../components/Typography";

class PanelPrint extends Component {

    render() {
        return (
            <div className="panel-print">
                <SimpleHeader title='Где я могу распечатать?' page='complete'/>
                <div className="panel-content">
                    <Typography
                        title="Типография ПМГ"
                        address="Москва, ул. Балтийская, д. 11 (м. Сокол)"
                        site="pmg.ru"
                        sale="Скидка 20% на любой заказ по промокоду «VK Business»"
                    />
                    <Typography
                        title="Креативная типография «Енот»"
                        address="Н.Новгород, ул. Мануфактурная, д. 14, офис 208/2"
                        site="enotnn.ru"
                        sale="Скидка 10% по промокоду «ВКонтакте»"
                    />
                    <Typography
                        title="Printsburg.ru"
                        address="Санкт-Петербург, пр. Науки, д. 19/2"
                        site="printsburg.ru"
                        sale="Скидка 10% от бонусной цены по промокоду «ДЫРОКОЛ»"
                        allAddresses="https://printsburg.ru/contacts/"
                    />
                    <Typography
                        title="МДМпринт"
                        address="Москва, Комсомольский проспект, 28"
                        site="mdmprint.ru"
                        sale="Скидка 10% по промокоду «QRVK20205»"
                        allAddresses="https://mdmprint.ru/contacts/#adress"
                    />
                    <Typography
                        title="Типография «А-цифра»"
                        address="Москва, ул. Новокузнецкая, д. 1, офис 43"
                        site="www.a-cifra.ru"
                        url="www.a-cifra.ru/regiony/"
                        sale="Скидки до 20% по промокоду «Скидка2020»"
                    />
                </div>
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
    };
}

export default connect(mapStateToProps)(PanelPrint);
