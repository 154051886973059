import React, { Component } from 'react';
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';


class SimpleHeader extends Component {

    goBack = () => {
        // if (this.props.page !== undefined) this.props.dispatch(push('/' + this.props.page));
        // else this.props.dispatch(push('/' + this.props.selectedMenu));
        this.props.dispatch(goBack());
    };

    render() {
        return (
            <div className="header-page">
                <div className="header-page__title">
                    <div className="icon-menu icon-back" onClick={ this.goBack }/>
                    <div className="header-page__name">{ this.props.title }</div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        selectedMenu: state.globals.selectedMenu,
    };
};

export default connect(mapStateToProps)(SimpleHeader);