import React, { Component } from 'react';
import { connect } from "react-redux";
import SimpleHeader from "../components/SimpleHeader";
import QRCode from "../components/QRCode";
import { push } from 'react-router-redux';
import { svgAsPngUri } from 'save-svg-as-png';
import {googleEvent, urlToGoogleLink, yandexEvent} from "../services/_functions";
import {saveLink} from "../services/axios";


class PanelDownload extends Component {

    openCustomize = () => {
        this.props.dispatch(push('/customize'));
    };

    savePng = () => {
        const qr = document.getElementById("qr-svg").children[0].children[0].children[0].children[0];
        svgAsPngUri(qr, {}).then(uri => {
            setTimeout(() => {
                svgAsPngUri(qr, {}).then(uri => {
                    let downloadLink = document.createElement("a");
                    downloadLink.href = uri;
                    downloadLink.download = `qr${((new Date).getTime() % 100000000000).toString().substr(0, 8)}.png`;
                    document.body.appendChild(downloadLink);

                    setTimeout(()=>{
                        downloadLink.click();
                        document.body.removeChild(downloadLink);
                    }, 500);

                    this.props.dispatch(push('complete'));
                    googleEvent('Download', 'png', urlToGoogleLink(this.props.selectedUrl));
                    yandexEvent('download_png_' + this.props.selectedUrl);
                });
            }, 500);
        });
        if (this.props.selectedMenu === 'link') saveLink(0, this.props.linkUrl);
    };

    saveSvg = () => {
        // get svg element.
        const svg = document.getElementById("qr-svg").children[0].children[0].children[0];

        // get svg source.
        const serializer = new XMLSerializer();
        let source = serializer.serializeToString(svg);

        // add name spaces.
        if(!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)){
            source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
        }
        if(!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)){
            source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
        }

        // add xml declaration
        source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

        // convert svg source to URI data scheme.
        const url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(source);

        // set url value to a element's href attribute.
        let downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = `qr${((new Date).getTime() % 100000000000).toString().substr(0, 8)}.svg`;
        document.body.appendChild(downloadLink);

        setTimeout(()=>{
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }, 500);

        this.props.dispatch(push('complete'));
        googleEvent('Download', 'svg', urlToGoogleLink(this.props.selectedUrl));
        yandexEvent('download_svg_' + this.props.selectedUrl);
        if (this.props.selectedMenu === 'link') saveLink(0, this.props.linkUrl);
    };

    componentDidMount = () => {
        document.getElementById('full-height').style.minHeight = 'calc(' + window.innerHeight + 'px - 116px)';
        document.getElementById('full-height-white').style.minHeight = 'calc(' + window.innerHeight + 'px - 116px)';
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    };

    render() {
        return (
            <React.Fragment>
                <SimpleHeader title='QR-код'/>
                <div id="canvas-container"/>
                <div className="container" id="full-height">
                    <div className="white-block white-block-no-margin">
                        <div className="white-block__inner white-block__inner--full" id="full-height-white">
                            <div className="QR-block__qr" id="qr-svg">
                                <QRCode/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-download">
                    <div className="btn btn-gray edit-icon" onClick={ this.openCustomize }/>
                    <div className="btn btn-blue" onClick={ this.savePng }>Скачать PNG</div>
                    <div className="btn btn-blue" onClick={ this.saveSvg }>Скачать SVG</div>
                </div>
            </React.Fragment>
        );
    }

}

function mapStateToProps(state) {
    return {
        selectedUrl: state.globals.selectedUrl,
        selectedMenu: state.globals.selectedMenu,
        linkUrl: state.settings.link.url,
    };
}

export default connect(mapStateToProps)(PanelDownload);
