import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import {setSelectedMenu} from "../store/globals/actions";
import {googleEvent, yandexEvent} from "../services/_functions";


class MenuItem extends Component {

    openLink = () => {
        this.props.dispatch(push(`/${this.props.link}`));
        this.props.dispatch(setSelectedMenu(this.props.link));
        switch(this.props.link) {
            case 'link':
                googleEvent('Content type', 'Link', 'Menu');
                yandexEvent('content_type_link_menu');
                break;
            case 'wifi':
                googleEvent('Content type', 'Wi-Fi', 'Menu');
                yandexEvent('content_type_wi-fi_menu');
                break;
            case 'contact':
                googleEvent('Content type', 'Contact', 'Menu');
                yandexEvent('content_type_contact_menu');
                break;
            case 'text':
                googleEvent('Content type', 'Text', 'Menu');
                yandexEvent('content_type_text_menu');
                break;
            case 'event':
                googleEvent('Content type', 'Event', 'Menu');
                yandexEvent('content_type_event_menu');
                break;
            case 'geo':
                googleEvent('Content type', 'Geo', 'Menu');
                yandexEvent('content_type_geo_menu');
                break;
        }

    };

    render() {
        let innerPlaceholder = <span className="Custom-select__input-block_text__placeholder">{ this.props.placeholder }</span>;

        return (
            <div className="main-menu__item">
                <div onClick={ this.openLink } className="main-menu__inner">
                    <div className="main-menu__link">
                        <div className={ "icon-menu " + this.props.link }/>
                        <div className="main-menu__name">{ this.props.title }</div>
                    </div>
                    <div className="main-menu__info">{ this.props.desc }</div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    };
};

export default connect(mapStateToProps)(MenuItem);