import * as types from "./actionTypes";
import * as React from "react";

const initialState = {
    page: '',
    modal: '',
    colorModal: '',
    link: '',
    selectedUrl: '',
    isShowReviewResult: false,
    selectedMenu: '',
    isRetargetingClose: false,
};

export const globals = (state = initialState, action) => {

    switch (action.type) {
        case types.SET_PAGE:
            return {
                ...state,
                page: action.page,
            };

        case types.SET_MODAL:
            if (action.modal !== '') document.body.classList.add("modal-opened");
            else document.body.classList.remove("modal-opened");
            return {
                ...state,
                modal: action.modal,
            };

        case types.SET_COLOR_MODAL:
            if (action.colorModal !== '') document.body.classList.add("modal-opened");
            else document.body.classList.remove("modal-opened");
            return {
                ...state,
                colorModal: action.colorModal,
            };

        case types.SET_SELECTED_URL:
            return {
                ...state,
                selectedUrl: action.selectedUrl,
            };

        case types.SET_SHOW_REVIEW_RESULT:
            return {
                ...state,
                isShowReviewResult: action.isShowReviewResult,
            };

        case types.SET_SELECTED_MENU:
            return {
                ...state,
                selectedMenu: action.selectedMenu,
            };

        case types.SET_RETARGETING_CLOSE:
            return {
                ...state,
                isRetargetingClose: true,
            };

        default:
            return state;
    }
};
