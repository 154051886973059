import React, { Component } from 'react';
import { connect } from "react-redux";
import Header from "../components/Header";
import BlockLook from "../components/BlockLook";
import Footer from "../components/Footer";
import {
    setEventDescription, setEventEndDate, setEventEndTimeHours, setEventEndTimeMinutes,
    setEventStartDate,
    setEventStartTimeHours,
    setEventStartTimeMinutes,
    setEventTitle
} from "../store/settings/actions";
import { UPDATE_TIME } from "../services/_locals";
import moment from "moment";
import {googleEvent, yandexEvent} from "../services/_functions";

class PanelEvent extends Component {

    state = {
        typing: false,
        timeout: undefined,
        startHours: this.props.eventStartTimeHours,
        startMinutes: this.props.eventStartTimeMinutes,
        endHours: this.props.eventEndTimeHours,
        endMinutes: this.props.eventStartTimeMinutes,
        isStartDateError: false,
        isEndDateError: false,
        focusDateStart: false,
        focusDateEnd: false,
        focusTImeStart: false,
        focusTimeEnd: false,
    };

    updateGoogleTimeoutName;
    updateGoogleTimeoutLink;
    updateGoogleTimeoutTimeStart;
    updateGoogleTimeoutTimeEnd;
    updateGoogleTimeoutDateStart;
    updateGoogleTimeoutDateEnd;

    changeHandler = (e) => {
        const val = e.target.value;
        clearTimeout(this.state.timeout);

        switch (e.target.id) {
            case 'event-title':
                this.setState({ timeout: setTimeout(() => this.props.dispatch(setEventTitle(val)), UPDATE_TIME) });
                clearTimeout(this.updateGoogleTimeoutName);
                this.updateGoogleTimeoutName = setTimeout(() => {
                    googleEvent('Edit', 'Event', 'Data_name');
                    yandexEvent('edit_event_data_name');
                }, 2500);
                break;

            case 'event-start-time-minutes':
                this.setState({ timeout: setTimeout(() => this.props.dispatch(setEventStartTimeMinutes(val)), UPDATE_TIME) });
                break;

            case 'event-end-time-minutes':
                this.setState({ timeout: setTimeout(() => this.props.dispatch(setEventEndTimeMinutes(val)), UPDATE_TIME) });
                break;

            case 'event-description':
                this.setState({ timeout: setTimeout(() => this.props.dispatch(setEventDescription(val)), UPDATE_TIME) });
                clearTimeout(this.updateGoogleTimeoutLink);
                this.updateGoogleTimeoutLink = setTimeout(() => {
                    googleEvent('Edit', 'Event', 'Data_link');
                    yandexEvent('edit_event_data_link');
                }, 2500);
                break;
        }
    };

    blurHandler = (e) => {
        clearTimeout(this.state.timeout);
        switch (e.target.id) {
            case 'event-title':
                if (e.target.value !== this.props.eventTitle) this.props.dispatch(setEventTitle(e.target.value));
                break;

            case 'event-start-time-hours':
                if (e.target.value !== this.props.eventStartTimeHours) this.props.dispatch(setEventStartTimeHours(e.target.value));
                break;

            case 'event-start-time-minutes':
                if (e.target.value !== this.props.eventStartTimeMinutes) this.props.dispatch(setEventStartTimeMinutes(e.target.value));
                break;

            case 'event-end-time-hours':
                if (e.target.value !== this.props.eventEndTimeHours) this.props.dispatch(setEventEndTimeHours(e.target.value));
                break;

            case 'event-end-time-minutes':
                if (e.target.value !== this.props.eventEndTimeMinutes) this.props.dispatch(setEventEndTimeMinutes(e.target.value));
                break;

            case 'event-description':
                if (e.target.value !== this.props.eventDescription) this.props.dispatch(setEventDescription(e.target.value));
                break;
        }
    };

    handleChangeDateStart = async(e) => {
        let date = e.target.value;
        if (date === null) date = '';
        await this.props.dispatch(setEventStartDate(date));
        this.checkStartDate();
        this.checkEndDate();

        clearTimeout(this.updateGoogleTimeoutDateStart);
        this.updateGoogleTimeoutDateStart = setTimeout(() => {
            googleEvent('Edit', 'Event', 'Data_time');
            yandexEvent('edit_event_data_time');
        }, 2500);
    };

    handleChangeDateEnd = async(e) => {
        let date = e.target.value;
        if (date === null) date = '';
        await this.props.dispatch(setEventEndDate(date));
        this.checkStartDate();
        this.checkEndDate();

        clearTimeout(this.updateGoogleTimeoutDateEnd);
        this.updateGoogleTimeoutDateEnd = setTimeout(() => {
            googleEvent('Edit', 'Event', 'Data_time');
            yandexEvent('edit_event_data_time');
        }, 2500);
    };

    handleChangeTimeStart = async(e) => {
        let time = e.target.value;
        if (time === null) time = '';
        if (time !== '') {
            await this.props.dispatch(setEventStartTimeHours(time.split(':')[0]));
            await this.props.dispatch(setEventStartTimeMinutes(time.split(':')[1]))
        }
        this.checkEndDate();

        clearTimeout(this.updateGoogleTimeoutTimeStart);
        this.updateGoogleTimeoutTimeStart = setTimeout(() => {
            googleEvent('Edit', 'Event', 'Data_time');
            yandexEvent('edit_event_data_time');
        }, 2500);
    };

    handleChangeTimeEnd = async(e) => {
        let time = e.target.value;
        if (time === null) time = '';
        if (time !== '') {
            await this.props.dispatch(setEventEndTimeHours(time.split(':')[0]));
            await this.props.dispatch(setEventEndTimeMinutes(time.split(':')[1]))
        }
        this.checkEndDate();

        clearTimeout(this.updateGoogleTimeoutTimeEnd);
        this.updateGoogleTimeoutTimeEnd = setTimeout(() => {
            googleEvent('Edit', 'Event', 'Data_time');
            yandexEvent('edit_event_data_time');
        }, 2500);
    };

    checkStartDate = () => {
        if (this.props.eventEndDate !== '' && this.props.eventStartDate === '') this.setState({ isStartDateError: true });
        else this.setState({ isStartDateError: false });
    };

    checkEndDate = () => {
        const dateStart = moment(this.props.eventStartDate).add(this.props.eventStartTimeHours, 'hours').add(this.props.eventStartTimeMinutes, 'minutes');
        const dateEnd = moment(this.props.eventEndDate).add(this.props.eventEndTimeHours, 'hours').add(this.props.eventEndTimeMinutes, 'minutes');
        if (dateStart > dateEnd) this.setState({ isEndDateError: true });
        else this.setState({ isEndDateError: false });
    };

    componentDidMount = () => {
        document.getElementById('full-height').style.minHeight = 'calc(' + window.innerHeight + 'px - 126px)';
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    };

    render() {
        return (
            <React.Fragment>
                <Header title='Событие' link='event'/>
                <div className="container" id="full-height">
                    <div className="white-block">
                        <div className={ 'white-block__inner' + (this.state.isStartDateError ? ' show-start-date-error' : '')  + (this.state.isEndDateError ? ' show-end-date-error' : '') }>
                            <div className="form-input">
                                <div className="form-input__name">Название события</div>
                                <input type="text" placeholder="Введите название" id='event-title' defaultValue={ this.props.eventTitle } onChange={ this.changeHandler } onBlur={ this.blurHandler }/>
                            </div>
                            <div className="two date-start">
                                <div className="form-input with-placeholder">
                                    <div className="form-input__name">Дата начала</div>
                                    <input type="date" defaultValue={ this.props.eventStartDate } onChange={ this.handleChangeDateStart }/>
                                    { this.props.eventStartDate === '' && <div className="input-placeholder">Введите дату</div> }
                                </div>
                                <div className="form-input with-placeholder">
                                    <div className="form-input__name with-placeholder">Время начала</div>
                                    <input type="time" defaultValue={ `${this.props.eventStartTimeHours}:${this.props.eventStartTimeMinutes}` } onChange={ this.handleChangeTimeStart }/>
                                    { (this.props.eventStartTimeHours === '' && this.props.eventStartTimeMinutes === '') && <div className="input-placeholder">Введите время</div> }
                                </div>
                            </div>
                            <div className="date-error date-start-error">Введена неверная дата</div>
                            <div className="two date-end">
                                <div className="form-input with-placeholder">
                                    <div className="form-input__name">Дата окончания</div>
                                    <input type="date" placeholder="Введите дату" defaultValue={ this.props.eventEndDate } onChange={ this.handleChangeDateEnd }/>
                                    { this.props.eventEndDate === '' && <div className="input-placeholder">Введите дату</div> }
                                </div>
                                <div className="form-input with-placeholder">
                                    <div className="form-input__name">Время окончания</div>
                                    <input type="time" placeholder="Введите время" defaultValue={ `${this.props.eventEndTimeHours}:${this.props.eventEndTimeMinutes}` } onChange={ this.handleChangeTimeEnd }/>
                                    { (this.props.eventEndTimeHours === '' && this.props.eventEndTimeMinutes === '') && <div className="input-placeholder">Введите время</div> }
                                </div>
                            </div>
                            <div className="date-error date-end-error">Введена неверная дата</div>
                            <div className="form-input">
                                <div className="form-input__name">Ссылка на событие</div>
                                <input type="text" placeholder="Введите ссылку" id='event-description' defaultValue={ this.props.eventDescription } onChange={ this.changeHandler } onBlur={ this.blurHandler }/>
                            </div>
                        </div>
                    </div>
                    <BlockLook/>
                </div>
                <Footer/>
            </React.Fragment>
        );
    }

}

function mapStateToProps(state) {
    return {
        eventTitle: state.settings.event.title,
        eventStartDate: state.settings.event.startDate,
        eventStartTimeHours: state.settings.event.startTimeHours,
        eventStartTimeMinutes: state.settings.event.startTimeMinutes,
        eventEndDate: state.settings.event.endDate,
        eventEndTimeHours: state.settings.event.endTimeHours,
        eventEndTimeMinutes: state.settings.event.endTimeMinutes,
        eventDescription: state.settings.event.description,
        isRetargetingClose: state.globals.isRetargetingClose,
    };
}

export default connect(mapStateToProps)(PanelEvent);
