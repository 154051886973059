import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setModal } from "../store/globals/actions";
import {
    setAdvancedIcon,
    setAdvancedIconSelected,
} from "../store/settings/actions";
import { uploadImage } from "../services/_functions";


class ModalUploadIcon extends Component {

    closeModal = () => {
        this.props.dispatch(setModal(''));
    };

    closeModalOwner = (e) => {
        if (e.target.classList.contains('ModalOwner')) {
            this.props.dispatch(setModal(''));
        }
    };

    uploadImage = (e) => {
        const successFunc = (result) => {
            this.props.dispatch(setAdvancedIcon(result));
            this.props.dispatch(setAdvancedIconSelected(4));
        };

        const failFunc = () => {
            this.closeModal();
        };

        this.props.dispatch(uploadImage(e, successFunc, failFunc));
    };

    clickSelect = () => {
        let file = document.getElementById('bg-modal-file');
        file.click();
    };

    render() {
        return (
            <div className="ModalOwner" onClick={ (e) => this.closeModalOwner(e) }>
                <div className="Modal">
                    <div className="Modal__content">
                        <div className="Modal__header">
                            <div className="Modal__header-title">Загрузка иконки</div>
                            <div className="Modal__close" onClick={ this.closeModal }/>
                        </div>
                        <div className="Modal__body">
                            <p>Можно загрузить картинку в формате .png, .jpg или .svg, размером не&nbsp;более&nbsp;5&nbsp;Мб</p>
                            <div className="Modal__buttons">
                                <div className="btn btn-blue" onClick={ this.clickSelect }>Выбрать файл</div>
                                <div className="btn btn-gray" onClick={ this.closeModal }>Отмена</div>
                            </div>
                            <input className="hide-it" type='file' id='bg-modal-file' onChange={ (e) => this.uploadImage(e) } />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    };
};

export default connect(mapStateToProps)(ModalUploadIcon);