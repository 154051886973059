import React from 'react';
import ReactGA from 'react-ga';
import loadImage from 'blueimp-load-image';
import {setModal} from "../store/globals/actions";
import ModalError from "../components/ModalError";
import ym from "react-yandex-metrika";
import { svgAsPngUri } from "save-svg-as-png";


// Падеж
export const wordPad = (num, t, ta, tov) => {
    num = num % 100;
    if (num % 10 === 1 && (num < 10 || num > 20)) return t;
    else if ((num % 10 >= 2) && (num % 10 <= 4) && (num < 10 || num > 20)) return ta;
    else return tov;
};

// Лидирующие нули
export const lead = (num, size) => {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
};

// Картинку в base64
export const toBase64 = (url, callback) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function() {
        const reader = new FileReader();
        reader.onloadend = async function() {
            await callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
};


// Универсальный загрузчик картинок
export const uploadImage = (e, successFunc, afterFunc, type) => (dispatch) =>{
    const file = e.target.files[0];
    if (file === undefined) return;

    if (file.size > 1024 * 1024 * 5) {
        dispatch(setModal(<ModalError error='Размер файла не должен превышать 5 Мб'/>));
        return;
    }

    const filename = file.name;
    const ext = filename.substr(filename.lastIndexOf('.') + 1, filename.length);
    if (['jpg', 'jpeg', 'png', 'svg'].indexOf(ext.toLowerCase()) === -1) {
        dispatch(setModal(<ModalError error='Расширение файла не соответствует .jpg, .png или .svg'/>));
        return;
    }

    let mW = 300;

    let options = {
        canvas: true,
        maxWidth: mW,
    };
    loadImage.parseMetaData(file, (data) => {
        if (data.exif) {
            options.orientation = data.exif.get('Orientation');
        }
        loadImage(file, async(img) => {
            if (img.type === 'error') {
            } else {
                await successFunc(img.toDataURL());
                afterFunc();
            }
        }, options);
    });
};

// ================
// Google Analytics
// ================
export const googleEvent = (category, action = '', label = undefined) => {
    ReactGA.event({
        category,
        action,
        label,
    });
};

export const yandexEvent = (target) => {
    ym('reachGoal', target);
};

export const urlToGoogleLink = (url) => {
    switch (url) {
        case 'link':
            return 'Link';
        case 'wifi':
            return 'Wi-Fi';
        case 'contact':
            return 'Contact';
        case 'text':
            return 'Text';
        case 'event':
            return 'Event';
        case 'geo':
            return 'Geo';
    }
};

export const hexToRgb = (hex) => {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
};


export const linkRelCanonical = link => {
    const el = document.getElementById('can-link-rel');
    if (el !== null) el.parentNode.removeChild(el);
    document.head.innerHTML += '<link id="can-link-rel" rel="canonical" href="https://vkqr.ru/' + link + '">';
};
