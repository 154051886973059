import * as types from './actionTypes';


export const setPage = (page) => (
    {
        type: types.SET_PAGE,
        page
    }
);

export const setModal = (modal) => (
    {
        type: types.SET_MODAL,
        modal
    }
);

export const setColorModal = (colorModal) => (
    {
        type: types.SET_COLOR_MODAL,
        colorModal
    }
);

export const setSelectedUrl = (selectedUrl) => (
    {
        type: types.SET_SELECTED_URL,
        selectedUrl
    }
);

export const setShowReviewResult = (isShowReviewResult) => (
    {
        type: types.SET_SHOW_REVIEW_RESULT,
        isShowReviewResult
    }
);

export const setSelectedMenu = (selectedMenu) => (
    {
        type: types.SET_SELECTED_MENU,
        selectedMenu
    }
);

export const setRetargetingClose = () => (
    {
        type: types.SET_RETARGETING_CLOSE,
    }
);
