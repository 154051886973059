import React, { Component } from 'react';
import { connect } from "react-redux";
import Header from "../components/Header";
import BlockLook from "../components/BlockLook";
import Footer from "../components/Footer";
import {UPDATE_TIME} from "../services/_locals";
import {setTextText} from "../store/settings/actions";
import {googleEvent, yandexEvent} from "../services/_functions";


class PanelText extends Component {

    state = {
        timeout: undefined,
    };

    updateGoogleTimeout;

    changeHandler = (e) => {
        const val = e.target.value;
        clearTimeout(this.state.timeout);

        this.setState({ timeout: setTimeout(() => this.props.dispatch(setTextText(val)), UPDATE_TIME) });

        clearTimeout(this.updateGoogleTimeout);
        this.updateGoogleTimeout = setTimeout(() => {
            googleEvent('Edit', 'Text', 'Data');
            yandexEvent('edit_text_data');
        }, 2500);
    };

    componentDidMount = () => {
        document.getElementById('full-height').style.minHeight = 'calc(' + window.innerHeight + 'px - 126px)';
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    };

    render() {
        return (
            <React.Fragment>
                <Header title='Текст' link='text'/>
                <div className="container" id="full-height">
                    <div className="white-block">
                        <div className="white-block__inner">
                            <div className="form-textarea mt-0">
                                <div className="form-textarea__name">Текст</div>
                                <textarea name="" id="" cols="" rows="4" placeholder="Введите текст" maxLength={ 110 } onChange={ this.changeHandler }>{ this.props.textText }</textarea>
                            </div>
                        </div>
                    </div>
                    <BlockLook/>
                </div>
                <Footer/>
            </React.Fragment>
        );
    }

}

function mapStateToProps(state) {
    return {
        textText: state.settings.text.text,
        isRetargetingClose: state.globals.isRetargetingClose,
    };
}

export default connect(mapStateToProps)(PanelText);
