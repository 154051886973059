import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    setAdvancedIconSelected, setLookCPIsBorder, setLookCPIsText, setLookSelectedType, setLookStyle1, setLookStyle2,
    setLookStyle3,
    setLookText
} from "../store/settings/actions";
import {googleEvent, yandexEvent} from "../services/_functions";
import {setColorModal, setModal} from "../store/globals/actions";
import ColorModalMultiple from "./ColorModalMultiple";
import ModalUploadBackground from "./ModalUploadBackground";


class BlockCustomizeStyle extends Component {

    handleOptionChangeStyle1 = (changeEvent) => {
        this.props.dispatch(setLookStyle1(+changeEvent.target.value));
        switch (+changeEvent.target.value) {
            case 1:
                this.props.dispatch(setAdvancedIconSelected(3, false));
                googleEvent('Edit', 'Color', 'Black');
                yandexEvent('edit_color_black');
                break;

            case 2:
                this.props.dispatch(setAdvancedIconSelected(2, false));
                googleEvent('Edit', 'Color', 'Blue');
                yandexEvent('edit_color_blue');
                break;

            case 3:
                googleEvent('Edit', 'Color', 'Open_color_picker');
                yandexEvent('edit_color_open_color_picker');
                break;
        }
    };

    handleOptionChangeStyle2 = (changeEvent) => {
        this.props.dispatch(setLookStyle2(+changeEvent.target.value));

        switch (+changeEvent.target.value) {
            case 1:
                this.props.dispatch(setAdvancedIconSelected(3, false));
                googleEvent('Edit', 'Color', 'Black');
                yandexEvent('edit_color_black');
                break;

            case 2:
                this.props.dispatch(setAdvancedIconSelected(2, false));
                googleEvent('Edit', 'Color', 'Blue');
                yandexEvent('edit_color_blue');
                break;

            case 3:
                googleEvent('Edit', 'Color', 'Open_color_picker');
                yandexEvent('edit_color_open_color_picker');
                break;
        }
    };

    handleOptionChangeStyle3 = (changeEvent) => {
        this.props.dispatch(setLookStyle3(+changeEvent.target.value));
        switch (+changeEvent.target.value) {
            case 1:
                this.props.dispatch(setAdvancedIconSelected(3, false));
                googleEvent('Edit', 'Color', 'Black');
                yandexEvent('edit_color_black');
                break;

            case 2:
                this.props.dispatch(setAdvancedIconSelected(2, false));
                googleEvent('Edit', 'Color', 'Blue');
                yandexEvent('edit_color_blue');
                break;

            case 3:
                googleEvent('Edit', 'Color', 'Transparent');
                yandexEvent('edit_color_transparent');
                break;

            case 4:
                googleEvent('Edit', 'Color', 'Open_color_picker');
                yandexEvent('edit_color_open_color_picker');
                break;
        }
    };

    openColorMultiple = () => {
        this.props.dispatch(setColorModal(<ColorModalMultiple/>));
    };

    openUploadBgModal = () => {
        this.props.dispatch(setModal(<ModalUploadBackground/>));
        googleEvent('Edit', 'Color', 'Picture');
        yandexEvent('edit_color_picture');
    };

    render() {
        let colorBlocks1 =
            <React.Fragment>
                <div className="group-form__radio">
                    <div className="form-radio-color color-black">
                        <input
                            type="radio"
                            id="radio-style-1"
                            name="radio-box-style1"
                            checked={ this.props.selectedStyle1 === 1 }
                            value="1"
                            onChange={ this.handleOptionChangeStyle1 }
                        />
                        <label htmlFor="radio-style-1"/>
                    </div>
                    <div className="form-radio-color color-blue">
                        <input
                            type="radio"
                            id="radio-style-2"
                            name="radio-box-style1"
                            checked={ this.props.selectedStyle1 === 2 }
                            value="2"
                            onChange={ this.handleOptionChangeStyle1 }
                        />
                        <label htmlFor="radio-style-2"/>
                    </div>
                    <div className="form-radio-color color-gradient">
                        <input
                            type="radio"
                            id="radio-style-3"
                            name="radio-box-style1"
                            checked={ this.props.selectedStyle1 === 3 }
                            value="3"
                            onChange={ this.handleOptionChangeStyle1 }
                            onClick={ () => this.openColorMultiple('radio-style-3') }
                        />
                        <label htmlFor="radio-style-3">
                            <span className="form-radio-color__option"/>
                        </label>
                    </div>
                </div>
            </React.Fragment>;

        let colorBlocks2 =
            <React.Fragment>
                <div className="group-form__radio">
                    <div className="form-radio-color color-black">
                        <input
                            type="radio"
                            id="radio-style-1"
                            name="radio-box-style2"
                            checked={ this.props.selectedStyle2 === 1 }
                            value="1"
                            onChange={ this.handleOptionChangeStyle2 }
                        />
                        <label htmlFor="radio-style-1"/>
                    </div>
                    <div className="form-radio-color color-blue">
                        <input
                            type="radio"
                            id="radio-style-2"
                            name="radio-box-style2"
                            checked={ this.props.selectedStyle2 === 2 }
                            value="2"
                            onChange={ this.handleOptionChangeStyle2 }
                        />
                        <label htmlFor="radio-style-2"/>
                    </div>
                    <div className="form-radio-color color-gradient">
                        <input
                            type="radio"
                            id="radio-style-3"
                            name="radio-box-style2"
                            checked={ this.props.selectedStyle2 === 3 }
                            value="3"
                            onChange={ this.handleOptionChangeStyle2 }
                            onClick={ () => this.openColorMultiple('radio-style-3') }
                        />
                        <label htmlFor="radio-style-3">
                            <span className="form-radio-color__option"/>
                        </label>
                    </div>
                </div>
            </React.Fragment>;

        let colorBlocks3 =
            <React.Fragment>
                <div className="group-form__radio">
                    <div className="form-radio-color color-black">
                        <input
                            type="radio"
                            id="radio-style-1"
                            name="radio-box-style3"
                            checked={ this.props.selectedStyle3 === 1 }
                            value="1"
                            onChange={ this.handleOptionChangeStyle3 }
                        />
                        <label htmlFor="radio-style-1"/>
                    </div>
                    <div className="form-radio-color color-blue">
                        <input
                            type="radio"
                            id="radio-style-2"
                            name="radio-box-style3"
                            checked={ this.props.selectedStyle3 === 2 }
                            value="2"
                            onChange={ this.handleOptionChangeStyle3 }
                        />
                        <label htmlFor="radio-style-2"/>
                    </div>
                    <div className="form-radio-color color-transparent">
                        <input
                            type="radio"
                            id="radio-style-3"
                            name="radio-box-style3"
                            checked={ this.props.selectedStyle3 === 3 }
                            value="3"
                            onChange={ this.handleOptionChangeStyle3 }
                        />
                        <label htmlFor="radio-style-3"/>
                    </div>
                    <div className="form-radio-color color-gradient">
                        <input
                            type="radio"
                            id="radio-style-4"
                            name="radio-box-style3"
                            checked={ this.props.selectedStyle3 === 4 }
                            value="4"
                            onChange={ this.handleOptionChangeStyle3 }
                            onClick={ () => this.openColorMultiple('radio-style-4') }
                        />
                        <label htmlFor="radio-style-4">
                            <span className="form-radio-color__option"/>
                        </label>
                    </div>
                    <div className="form-radio-color">
                        <div className="form-radio-color_color-photo" onClick={ this.openUploadBgModal }/>
                    </div>
                </div>
            </React.Fragment>;

        return (
            <React.Fragment>
                { (this.props.selectedType === 1) && colorBlocks1 }
                { (this.props.selectedType === 2) && colorBlocks2 }
                { (this.props.selectedType === 3) && colorBlocks3 }
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        selectedType: state.settings.look.selectedType,
        selectedStyle1: state.settings.look.selectedStyle1,
        selectedStyle2: state.settings.look.selectedStyle2,
        selectedStyle3: state.settings.look.selectedStyle3,
    };
};

export default connect(mapStateToProps)(BlockCustomizeStyle);