import React, { Component } from 'react';
import { connect } from 'react-redux';
import {googleEvent, uploadImage, wordPad, yandexEvent} from "../services/_functions";
import {
    setAdvancedLink,
    setAdvancedTitle, setAdvancedTopIcon, setAdvancedTopIconLoaded,
    setAdvancedWithImageAndText
} from "../store/settings/actions";


class BlockCustomizeAdvanced extends Component {

    state = {
        charsLeftTitle: 18 - this.props.title.length,
        charsLeftSubTitle: 30 - this.props.link.length,
    };

    handleOptionChange = (changeEvent) => {
        this.props.dispatch(setAdvancedWithImageAndText(changeEvent.target.checked));

        if (changeEvent.target.checked) {
            googleEvent('Edit', 'Avatar', 'On');
            yandexEvent('edit_avatar_on');
        } else {
            googleEvent('Edit', 'Avatar', 'Off');
            yandexEvent('edit_avatar_off');
        }
    };

    updateGoogleTimeoutTitle;
    updateGoogleTimeoutDesc;

    uploadImage = (e) => {
        const successFunc = (result) => {
            this.props.dispatch(setAdvancedTopIcon(result));
            this.props.dispatch(setAdvancedTopIconLoaded(true));
        };

        const failFunc = () => {};

        this.props.dispatch(uploadImage(e, successFunc, failFunc));
    };

    clickSelect = () => {
        let file = document.getElementById('top-icon-file');
        file.value = '';
        if (!/safari/i.test(navigator.userAgent)) {
            file.type = '';
            file.type = 'file';
        }
        file.click();
        googleEvent('Edit', 'Avatar', 'Upload');
        yandexEvent('edit_avatar_upload');
    };

    unloadImage = () => {
        this.props.dispatch(setAdvancedTopIcon(''));
        this.props.dispatch(setAdvancedTopIconLoaded(false));
    };

    changeTitleHandler = (e) => {
        this.props.dispatch(setAdvancedTitle(e.target.value));
        this.setState({ charsLeftTitle: 18 - e.target.value.length });

        clearTimeout(this.updateGoogleTimeoutTitle);
        this.updateGoogleTimeoutTitle = setTimeout(() => {
            googleEvent('Edit', 'Page_title', 'New');
            yandexEvent('edit_page_title_new');
        }, 2500);
    };

    changeLinkHandler = (e) => {
        this.props.dispatch(setAdvancedLink(e.target.value));
        this.setState({ charsLeftSubTitle: 30 - e.target.value.length });

        clearTimeout(this.updateGoogleTimeoutDesc);
        this.updateGoogleTimeoutDesc = setTimeout(() => {
            googleEvent('Edit', 'Page_link', 'New');
            yandexEvent('edit_page_link_new');
        }, 2500);
    };

    render() {
        let imgStyle = {};
        if (this.props.topIconUploaded !== '') imgStyle = { backgroundImage: ('url(' + this.props.topIconUploaded + ')'), backgroundSize: 'cover' };

        const img = <div className="form-add-img circle">
            <div className="form-add-img__name">Изображение</div>
            <div className="form-add-img__box">
                <div className="form-add-img__img" style={ imgStyle }/>
                <div className="form-add-img__info">
                    <div className="group-box">
                        <div className="btn btn-gray" onClick={ this.clickSelect }>Загрузить</div>
                        { this.props.isTopIconLoaded && <div className="btn btn-gray__del" onClick={ this.unloadImage }/> }
                        <input className="hide-it" type='file' id='top-icon-file' onChange={ (e) => this.uploadImage(e) } />
                    </div>
                </div>
            </div>
        </div>;

        let leftTitle = 'Осталось ' + this.state.charsLeftTitle + wordPad(this.state.charsLeftTitle, ' символ', ' символа', ' символов');
        if  (this.state.charsLeftTitle === 0) leftTitle = 'Достигнута максимальная длина текста';
        let leftSubTitle = 'Осталось ' + this.state.charsLeftSubTitle + wordPad(this.state.charsLeftSubTitle, ' символ', ' символа', ' символов');
        if  (this.state.charsLeftSubTitle === 0) leftSubTitle = 'Достигнута максимальная длина текста';

        const imageAndText =
            <React.Fragment>
                { !(this.props.selectedType === 4 && this.props.bgImage !== '') && img }
                <div className="form-input">
                    <div className="form-input__name">Заголовок</div>
                    <input maxLength={ 18 } type="text" placeholder="Введите заголовок..." onChange={ this.changeTitleHandler } value={ this.props.title } />
                    <div className="form-input__hint">{ leftTitle }</div>
                </div>
                <div className="form-input">
                    <div className="form-input__name">Подзагловок</div>
                    <input maxLength={ 30 } type="text" placeholder="Введите подзаголовок..." onChange={ this.changeLinkHandler } value={ this.props.link }/>
                    <div className="form-input__hint">{ leftSubTitle }</div>
                </div>
            </React.Fragment>;

        const imageAndTextFull =
            <div className="form-toggle">
                <input type="checkbox" id="picture-and-text" checked={ this.props.withImageAndText } onChange={ this.handleOptionChange } />
                <label htmlFor="picture-and-text">Блок с картинкой и названием</label>
                { this.props.withImageAndText && imageAndText }
            </div>;

        return (
            <React.Fragment>
                { imageAndTextFull }
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        selectedType: state.settings.look.selectedType,
        withImageAndText: state.settings.advanced.withImageAndText,
        iconSelected: state.settings.advanced.iconSelected,
        title: state.settings.advanced.title,
        link: state.settings.advanced.link,
        topIconUploaded: state.settings.advanced.topIconUploaded,
        bgImage: state.settings.look.bgImage,
        isTopIconLoaded: state.settings.advanced.isTopIconLoaded,
    };
};

export default connect(mapStateToProps)(BlockCustomizeAdvanced);