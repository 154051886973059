import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setModal } from "../store/globals/actions";


class ModalError extends Component {

    closeModal = () => {
        this.props.dispatch(setModal(''));
    };

    closeModalOwner = (e) => {
        if (e.target.classList.contains('ModalOwner')) {
            this.props.dispatch(setModal(''));
        }
    };

    render() {
        return (
            <div className="ModalOwner" onClick={ (e) => this.closeModalOwner(e) }>
                <div className="Modal">
                    <div className="Modal__content">
                        <div className="Modal__header">
                            <div className="Modal__header-title">Ошибка</div>
                            <div className="Modal__close" onClick={ this.closeModal }/>
                        </div>
                        <div className="Modal__body">
                            <p>{ this.props.error }</p>
                            <div className="Modal__buttons">
                                <div className="btn btn-blue" onClick={ this.closeModal }>ОК</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    };
};

export default connect(mapStateToProps)(ModalError);