import React, { Component } from 'react';
import { connect } from "react-redux";
import SimpleHeader from "../components/SimpleHeader";
import { setModal } from "../store/globals/actions";
import { addReview } from "../services/axios";
import ModalReviewDone from "../components/ModalReviewDone";


class PanelFeedback extends Component {

    state = {
        review: '',
        isLike: 1,
        reviewError: false,
    };

    sendFeedback = () => {
        if (this.state.review !== '') {
            addReview(0, this.state.review, this.state.isLike);
            this.props.dispatch(setModal(<ModalReviewDone/>));
        } else {
            this.setState({ reviewError: true });
        }
    };

    onChangeReview = (e) => {
        if (e.target.value !== '') this.setState({ review: e.target.value, reviewError: false });
        else this.setState({ review: e.target.value });
    };

    onChangeIsLike = (e) => {
        this.setState({ isLike: e.target.value });
    };

    componentDidMount = () => {
        document.getElementById('full-height').style.minHeight = 'calc(' + window.innerHeight + 'px - 137px)';
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    };

    render() {
        return (
            <React.Fragment>
                <SimpleHeader page="complete" title='Обратная связь'/>
                <div className="feedback-block">
                    <div className="feedback-block__content" id="full-height">
                        <div className="group-form mt-0">
                            <div className="form-input__name">Нравится ли Вам сервис?</div>
                            <div className="group-form__radio flex-direction-column">
                                <div className="form-radio">
                                    <input type="radio" id="radio-1" name="radio-box" defaultChecked={ this.state.isLike === 1 } value={ 1 } onChange={ this.onChangeIsLike }/>
                                        <label htmlFor="radio-1">Да</label>
                                </div>
                                <div className="form-radio">
                                    <input type="radio" id="radio-2" name="radio-box" defaultChecked={ this.state.isLike === 0 } value={ 0 } onChange={ this.onChangeIsLike }/>
                                        <label htmlFor="radio-2">Нет</label>
                                </div>
                            </div>
                        </div>
                        <div className="form-textarea">
                            <div className="form-textarea__name">Ваш отзыв</div>
                            <textarea name="" id="" cols="" rows="4" placeholder="Поделитесь с нами своими мыслями по улучшению сервиса..." maxLength={ 1000 } value={ this.state.review } onChange={ this.onChangeReview } className={ this.state.reviewError ? 'textarea-error' : '' }/>
                            <div className={ "textarea-desc-error" + (this.state.reviewError ? ' show' : '') }>Введите текст сообщения</div>
                        </div>
                    </div>
                    <div className="feedback-btn">
                        <div className="btn btn-blue" onClick={ this.sendFeedback }>Отправить</div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

}

function mapStateToProps(state) {
    return {
    };
}

export default connect(mapStateToProps)(PanelFeedback);
