import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    setAdvancedIconSelected, setLookCPIsBorder, setLookCPIsText, setLookSelectedType,
    setLookText
} from "../store/settings/actions";
import {googleEvent, yandexEvent} from "../services/_functions";


class BlockCustomizeType extends Component {

    handleOptionChange = (changeEvent) => {
        this.props.dispatch(setLookSelectedType(+changeEvent.target.value));
        // Тип 3 включаем бордер
        if (+changeEvent.target.value === 3) this.props.dispatch(setLookCPIsBorder(true));
        else this.props.dispatch(setLookCPIsBorder(false));
        // Тип 2 или 3 включаем по умолчанию рамку "откройте камерой ВК"
        if ((+changeEvent.target.value === 2 || +changeEvent.target.value === 3) && this.props.lookText === '') {
            if (!this.props.isTextChangedByUser) this.recalcLeft(11, 'Откройте камерой VK', false);
        }

        // Если юзер не выбирал кастомную иконку, то выбираем иконку в зависимости от стиля
        if (!this.props.isIconSelectedByUser) {
            if (+changeEvent.target.value === 1) {
                if (this.props.selectedStyle1 === 1) this.props.dispatch(setAdvancedIconSelected(3, false));
                if (this.props.selectedStyle1 === 2) this.props.dispatch(setAdvancedIconSelected(2, false));
            }
            if (+changeEvent.target.value === 2) {
                if (this.props.selectedStyle2 === 1) this.props.dispatch(setAdvancedIconSelected(3, false));
                if (this.props.selectedStyle2 === 2) this.props.dispatch(setAdvancedIconSelected(2, false));
            }
            if (+changeEvent.target.value === 3) {
                if (this.props.selectedStyle3 === 1) this.props.dispatch(setAdvancedIconSelected(3, false));
                if (this.props.selectedStyle3 === 2) this.props.dispatch(setAdvancedIconSelected(2, false));
            }
        }

        switch (+changeEvent.target.value) {
            case 1:
                googleEvent('Edit', 'Type', 'Plain_code');
                yandexEvent('edit_type_plain_code');
                break;

            case 2:
                googleEvent('Edit', 'Type', 'With_frame');
                yandexEvent('edit_type_with_frame');
                break;

            case 3:
                googleEvent('Edit', 'Type', 'With_background');
                yandexEvent('edit_type_with_background');
                break;

            case 4:
                googleEvent('Edit', 'Type', 'With_picture');
                yandexEvent('edit_type_with_picture');
                break;
        }
    };

    recalcLeft = (left, text, isTextChangedByUser = true) => {
        this.setState({ charsLeft: left });
        this.props.dispatch(setLookText(text, isTextChangedByUser));
        if (text !== '') this.props.dispatch(setLookCPIsText(true));
        else this.props.dispatch(setLookCPIsText(false));
    };

    render() {
        return (
            <React.Fragment>
                <div className="group-form mt-0">
                    <div className="group-form__radio flex-direction-column">
                        <div className="form-radio">
                            <input type="radio" id="type-encryption-1" name="radio-box" value="1" checked={ this.props.selectedType === 1 } onChange={ this.handleOptionChange } />
                            <label htmlFor="type-encryption-1">Только код</label>
                        </div>
                        <div className="form-radio">
                            <input type="radio" id="type-encryption-2" name="radio-box" value="2" checked={ this.props.selectedType === 2 } onChange={ this.handleOptionChange }/>
                            <label htmlFor="type-encryption-2">С рамкой</label>
                        </div>
                        <div className="form-radio">
                            <input type="radio" id="type-encryption-3" name="radio-box" value="3" checked={ this.props.selectedType === 3 } onChange={ this.handleOptionChange }/>
                            <label htmlFor="type-encryption-3">С фоном</label>
                        </div>
                        <div className="form-radio">
                            <input type="radio" id="type-encryption-4" name="radio-box" value="4" checked={ this.props.selectedType === 4 } onChange={ this.handleOptionChange } />
                            <label htmlFor="type-encryption-4">Карточка</label>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        lookText: state.settings.look.text,
        selectedType: state.settings.look.selectedType,
        selectedStyle1: state.settings.look.selectedStyle1,
        selectedStyle2: state.settings.look.selectedStyle2,
        selectedStyle3: state.settings.look.selectedStyle3,
        isIconSelectedByUser: state.settings.advanced.isIconSelectedByUser,
        isTextChangedByUser: state.settings.look.isTextChangedByUser,
    };
};

export default connect(mapStateToProps)(BlockCustomizeType);