import React, { Component } from 'react';
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';


class Header extends Component {

    openMain = () => {
        this.props.dispatch(goBack());
    };

    render() {
        return (
            <div className="header-page">
                <div className="header-page__title">
                    <div className={ "icon-menu " + this.props.link }/>
                    <div className="header-page__name">{ this.props.title }</div>
                </div>
                <div className="header-page__type" onClick={ this.openMain }>Изменить тип</div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
    };
};

export default connect(mapStateToProps)(Header);