import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../src/scss/style.scss';
import { setSelectedMenu, setSelectedUrl} from "./js/store/globals/actions";
import ReactGA from 'react-ga';
import PanelMain from "./js/panels/PanelMain";
import PanelLink from "./js/panels/PanelLink";
import PanelWifi from "./js/panels/PanelWifi";
import PanelContact from "./js/panels/PanelContact";
import PanelText from "./js/panels/PanelText";
import PanelEvent from "./js/panels/PanelEvent";
import PanelGeo from "./js/panels/PanelGeo";
import PanelDownload from "./js/panels/PanelDownload";
import PanelCustomize from "./js/panels/PanelCustomize";
import PanelFeedback from "./js/panels/PanelFeedback";
import PanelAbout from "./js/panels/PanelAbout";
import {linkRelCanonical} from "./js/services/_functions";
import PanelComplete from "./js/panels/PanelComplete";
import PanelPrint from "./js/panels/PanelPrint";


class App extends Component {

    componentWillMount() {
    }

    render() {
        let panel = '';
        let selected = '';
        let h1 = '';

        switch (this.props.pageId) {
            case '':
                panel = <PanelMain/>;
                selected = this.props.pageId;
                document.title = 'Генератор QR кодов – зашифровка онлайн';
                linkRelCanonical('');
                h1 ='Генератор QR-кодов';
                break;

            case 'link':
                panel = <PanelLink/>;
                selected = this.props.pageId;
                document.title = 'Генератор QR-кодов — создать код со ссылкой бесплатно';
                this.props.dispatch(setSelectedMenu(selected));
                linkRelCanonical('link');
                h1 ='QR-код со ссылкой';
                break;

            case 'wifi':
                panel = <PanelWifi/>;
                selected = this.props.pageId;
                document.title = 'Создать QR-код для подключения к Wi-Fi';
                this.props.dispatch(setSelectedMenu(selected));
                linkRelCanonical('wifi');
                h1 ='QR-код для настройки wi-fi';
                break;

            case 'contact':
                panel = <PanelContact/>;
                selected = this.props.pageId;
                document.title = 'Создать визитку с QR-кодом – онлайн генератор';
                this.props.dispatch(setSelectedMenu(selected));
                linkRelCanonical('contact');
                h1 ='QR-визитка';
                break;

            case 'text':
                panel = <PanelText/>;
                selected = this.props.pageId;
                document.title = 'Создать QR-код текста – онлайн зашифровка';
                this.props.dispatch(setSelectedMenu(selected));
                linkRelCanonical('text');
                h1 ='QR-код текста';
                break;

            case 'event':
                panel = <PanelEvent/>;
                selected = this.props.pageId;
                document.title = 'Создать QR-код события, мероприятия или календаря онлайн';
                this.props.dispatch(setSelectedMenu(selected));
                linkRelCanonical('event');
                h1 ='QR-код для мероприятий';
                break;

            case 'geo':
                panel = <PanelGeo/>;
                selected = this.props.pageId;
                document.title = 'Создать QR-код геолокации онлайн — поделиться координатами местоположения';
                this.props.dispatch(setSelectedMenu(selected));
                linkRelCanonical('geo');
                h1 ='QR-код местоположения';
                break;

            case 'download':
                panel = <PanelDownload/>;
                selected = this.props.pageId;
                document.title = 'Скачать QR-код онлайн';
                break;

            case 'customize':
                panel = <PanelCustomize/>;
                selected = this.props.pageId;
                document.title = 'Создать QR-код онлайн';
                break;

            case 'feedback':
                panel = <PanelFeedback/>;
                selected = this.props.pageId;
                document.title = 'Создать QR-код онлайн';
                break;

            case 'info':
                panel = <PanelAbout/>;
                selected = this.props.pageId;
                document.title = 'Что такое QR-код';
                linkRelCanonical('info');
                break;

            case 'complete':
                panel = <PanelComplete/>;
                selected = this.props.pageId;
                document.title = 'Создать QR-код онлайн';
                break;

            case 'print':
                panel = <PanelPrint/>;
                selected = this.props.pageId;
                document.title = 'Создать QR-код онлайн';
                break;

            default:
                panel = <PanelMain/>;
                selected = '';
                document.title = 'Генератор QR кодов – зашифровка онлайн';
                break;
        }

        ReactGA.pageview(`/${selected}`);
        this.props.dispatch(setSelectedUrl(selected));

        return (
            <React.Fragment>
                { panel }
                { this.props.modal }
                { this.props.colorModal }
                <h1 className='hide-vis'>{h1}</h1>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        modal: state.globals.modal,
        colorModal: state.globals.colorModal,
        smallHeader: state.globals.smallHeader,
    };
}

export default connect(mapStateToProps)(App);
