import React, { Component } from 'react';
import MenuItem from "../components/MenuItem";
import { push } from 'react-router-redux';
import { connect } from "react-redux";


class PanelMain extends Component {

    openAbout = () => {
        this.props.dispatch(push('/info'));
    };

    componentDidMount() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    render() {
        return (
            <React.Fragment>
                <div className="header">
                    <div className="header__title">
                        <div className="logo"/>
                        <span>QR-генератор</span>
                    </div>
                    <div className="header__info">
                        <p>Помогите клиентам быстро найти Вашу страницу в интернете.</p>
                        <p>Используйте QR-коды — и делитесь ссылками на Ваши онлайн-ресурсы в офлайне.</p>
                    </div>
                    <div className="header__btn">
                        <div className="w-100 btn btn-transparent" onClick={ this.openAbout }>Что такое QR?</div>
                        {/*<div className="btn btn-white">Войти через VK</div>*/}
                    </div>
                </div>
                <div className="main-menu">
                    <MenuItem
                        link='link'
                        title='Ссылка'
                        desc='Вы можете поделиться ссылкой на ваш сайт, сообщество ВКонтакте или любой другой ресурс при помощи QR-кода'
                    />
                    <MenuItem
                        link='wifi'
                        title='Настройки Wi-Fi'
                        desc='Избавьте клиентов от необходимости искать пароль от Wi-Fi, а затем вводить его вручную'
                    />
                    <MenuItem
                        link='contact'
                        title='Визитка'
                        desc='Зашифруйте в QR-коде свои контактные данные: имя, должность, номер телефона и другое'
                    />
                    <MenuItem
                        link='text'
                        title='Текст'
                        desc='Разместите QR-код на товаре — клиенты смогут отсканировать его и прочитать всю зашифрованную информацию на своём телефоне'
                    />
                    <MenuItem
                        link='event'
                        title='Событие'
                        desc='Ваши клиенты смогут легко добавить напоминание о событии в календарь телефона'
                    />
                    <MenuItem
                        link='geo'
                        title='Геолокация'
                        desc='В QR-коде можно зашифровать координаты и быстро поделиться местоположением: например, Вашего заведения'
                    />
                </div>
            </React.Fragment>
        );
    }

}

function mapStateToProps(state) {
    return {
    };
}

export default connect(mapStateToProps)(PanelMain);