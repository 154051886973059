import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';


class Footer extends Component {

    openDownload = () => {
        this.props.dispatch(push('/download'));
    };

    openCustomize = () => {
        this.props.dispatch(push('/customize'));
    };

    render() {
        return (
            <div className="footer-btn">
                <div className="btn btn-gray" onClick={ this.openCustomize }>Кастомизация</div>
                <div className="btn btn-blue" onClick={ this.openDownload }>Скачать</div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        isShowReviewResult: state.globals.isShowReviewResult,
    };
};

export default connect(mapStateToProps)(Footer);