import "core-js/es6";
import "core-js/es7";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { Route } from 'react-router';
import { applyMiddleware, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { rootReducer } from './js/store/reducers';
import { ConnectedRouter, routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import ReactGA from 'react-ga';
import YMInitializer from 'react-yandex-metrika/lib/init'

// import registerServiceWorker from './sw';

const history = createBrowserHistory();

const logger = store => next => action => {
    if (process.env.NODE_ENV === 'development') console.log('dispatching', action);
    return next(action);
};

export const store = createStore(rootReducer, composeWithDevTools(
    applyMiddleware(thunk, routerMiddleware(history), logger),
));

const urls = [
    '',
    'link',
    'wifi',
    'contact',
    'text',
    'event',
    'geo',
    'info',
    'download',
    'customize',
    'feedback',
    'complete',
    'print',
];

ReactGA.initialize('UA-141207104-1');
YMInitializer([54051610], { webvisor: true }, '2');

const pathRegex = "/:pageId(" + urls.join('|') + "|)?";

ReactDOM.render(
    <Provider store={ store }>
        <ConnectedRouter history={ history }>
            <Route path={ pathRegex } component={ (props) => <App pageId={ props.match.params.pageId }/> }/>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);
